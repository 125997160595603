import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

function useRouteChange(): [boolean, string] {
  const router = useRouter();
  const [isRouteChanged, setRouteChanged] = useState<boolean>(false);
  const [url, setUrl] = useState<string>('');

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      setUrl(url);
      setRouteChanged(true);
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router]);

  return [isRouteChanged, url];
}

export default useRouteChange;
