import { useRouter } from 'next/router';
import { useQuery } from '@tanstack/react-query';
import { ScenariosBetaApi } from 'src/api';
import { ScenariosBetaStatusResponse } from 'src/api/scenariosBeta/types';
import { ROUTES } from '../constants/routes.const';
import useSafePush from './useSafePush';

const betaOnRedirects: Record<string, string | undefined> = {
  [ROUTES.dashboard.old]: ROUTES.dashboard.scenarios,
};

const betaOffRedirects: Record<string, string | undefined> = {
  [ROUTES.dashboard.scenarios]: ROUTES.dashboard.old,
  [ROUTES.scenarios.learn]: ROUTES.dashboard.old,
  [ROUTES.scenarios.practice]: ROUTES.dashboard.old,
  [ROUTES.languages]: ROUTES.dashboard.old,
  [ROUTES.video.immerse]: ROUTES.dashboard.old,
  [ROUTES.membot.select]: ROUTES.dashboard.old,
  [ROUTES.dictionary]: ROUTES.dashboard.old,
  [ROUTES.progress]: ROUTES.dashboard.old,
};

export const getRedirects = (isBeta?: boolean) => {
  return isBeta ? betaOnRedirects : betaOffRedirects;
};

/**
 * Redirects the user if necessary based on their beta status for scenarios.
 * Returns true when the user should stay on the current page after all checks are finished.
 * Returns false while checking or if the user should be redirected.
 * This can be used to prevent other queries from happening,
 * they should only be made after this hook returns true.
 */
export function useScenariosBetaRedirect(): {
  shouldStay: boolean;
  refetch: () => void;
} {
  const router = useRouter();
  const { safePush } = useSafePush();

  const {
    data: status,
    isFetched: isReady,
    refetch,
  } = useQuery<ScenariosBetaStatusResponse>({
    queryKey: ['scenariosBetaStatus'],
    queryFn: () => {
      return ScenariosBetaApi.getScenariosBetaStatus();
    },
  });

  const redirects = getRedirects(status?.isJoined);

  if (!isReady) return { shouldStay: false, refetch };

  const redirectUrl = redirects[router.pathname];

  if (redirectUrl) {
    void safePush(redirectUrl);
    return { shouldStay: false, refetch };
  }

  return { shouldStay: true, refetch };
}
