import { useEffect, useCallback, useState, MutableRefObject } from 'react';

/**
 * Use the hover state of an html element
 *
 * @example
 *
 * // In some component body...
 *
 * const divRef = useRef();
 * const isHovered = useHover<HTMLDivElement>(divRef);
 *
 * useEffect(() => {
 *   // do something on hover state change
 * }, [isHovered]);
 *
 * return (
 *  <div ref={divRef}/>
 * )
 */
function useHover<ElementType extends HTMLElement>(
  ref: MutableRefObject<ElementType | null>,
): boolean {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseOver = useCallback(() => {
    setIsHovered(true);
  }, [setIsHovered]);
  const handleMouseOut = useCallback(() => {
    setIsHovered(false);
  }, [setIsHovered]);

  useEffect(() => {
    const node = ref.current;
    /* istanbul ignore next: untested branch of code, please test */
    if (node) {
      node.addEventListener('mouseover', handleMouseOver);
      node.addEventListener('mouseout', handleMouseOut);

      return () => {
        node.removeEventListener('mouseover', handleMouseOver);
        node.removeEventListener('mouseout', handleMouseOut);
      };
    }
  }, [handleMouseOver, handleMouseOut, ref]);

  return isHovered;
}

export default useHover;
