/* istanbul ignore file: didn't work with document.createElement */
import { useCallback, useEffect, useState } from 'react';

const useElementsOverlap = (
  elementA?: HTMLElement | null,
  elementB?: HTMLElement | null,
  resizedElement?: HTMLElement | null,
  eventType?: string,
): boolean | undefined => {
  const [isOverlapped, setIsOverlapped] = useState<boolean>(false);

  const listener = useCallback(() => {
    if (!elementA || !elementB) return;

    const rectA = elementA.getBoundingClientRect();
    const rectB = elementB.getBoundingClientRect();

    if (
      !(
        rectA.top > rectB.bottom ||
        rectA.right < rectB.left ||
        rectA.bottom < rectB.top ||
        rectA.left > rectB.right
      )
    ) {
      setIsOverlapped(true);
    } else {
      setIsOverlapped(false);
    }
  }, [elementA, elementB]);

  useEffect(() => {
    if (!eventType) return;

    resizedElement?.addEventListener(eventType, listener);
    return () => {
      resizedElement?.removeEventListener(eventType, listener);
    };
  }, [eventType, listener, resizedElement]);

  return isOverlapped;
};

export default useElementsOverlap;
