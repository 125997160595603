import { useCallback, useEffect, useState } from 'react';

const useDetectKeyboardOpen = (minKeyboardHeight = 300): boolean => {
  const [isKeyboardOpen, setIsKeyboardOpen] = useState<boolean>(false);

  const listener = useCallback(() => {
    const newState =
      window.screen.height - minKeyboardHeight >
      // visualViewport can't be null here as this listener is in the visualViewport itself
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      window.visualViewport!.height;

    /* istanbul ignore next: already mocked */
    if (isKeyboardOpen !== newState) {
      setIsKeyboardOpen(newState);
    }
  }, [isKeyboardOpen, minKeyboardHeight]);

  useEffect(() => {
    window.visualViewport?.addEventListener('resize', listener);
    return () => {
      window.visualViewport?.removeEventListener('resize', listener);
    };
  }, [listener]);

  return isKeyboardOpen;
};

export default useDetectKeyboardOpen;
